[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

input[disabled]:hover {cursor: default !important;}
label.switch[disabled]:hover {cursor:default !important;}

@import "_coreui.scss";
@import "node_modules/simple-line-icons/scss/simple-line-icons.scss";

header.navbar .navbar-brand {
  background-image: url('/public/images/icons/app/svg/tellit-icon.svg');
  background-size: contain;
}

header.navbar .navbar-brand.culturemoves {
  background-image: url('/public/hubs/CMoves/Logo_Culturemoves-01_SR-64.jpg');
  background-size: contain;
  background-origin: content-box;
  padding: 3px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% {  transform: rotate(359deg); }
}

.icon-spin {
  display:inline-block;
  animation: spin 2s linear infinite;
}

.card-block {
    word-break: break-word;
}

.full-width {width: 100%;}

a.img:hover {
    text-decoration: none;
}

.icon-twitter {background: url("/public/images/icons/twitter.png") no-repeat; background-size: cover;}
.icon-pinterest {background: url("/public/images/icons/pinterest.png") no-repeat; background-size: cover;}
.icon-facebook {background: url("/public/images/icons/facebook.png") no-repeat; background-size: cover;}
.icon-flickr {background: url("/public/images/icons/flickr.png") no-repeat; background-size: cover;}
.icon-followtheplace {background: url("/public/images/icons/followtheplace.png") no-repeat; background-size: cover;}
.icon-instagram {background: url("/public/images/icons/instagram.png") no-repeat; background-size: cover;}
.icon-rss {background: url("/public/images/icons/rss.png") no-repeat; background-size: cover;}
.icon-web {background: url("/public/images/icons/rss.png") no-repeat; background-size: cover;}
.icon-vimeo {background: url("/public/images/icons/vimeo.png") no-repeat; background-size: cover;}
.icon-youtube {background: url("/public/images/icons/youtube.png") no-repeat; background-size: cover;}

.social-box.youtube i {
  color: #fff;
  background: $youtube;
}

.social-box.rss i {
  color: #fff;
  background: #faa21b;
}

.social-box.instagram i {
  color: #fff;
  background: $instagram;
}

.social-box.flickr i {
  color: #fff;
  background: $flickr;
}

.social-box.vimeo i {
  color: #fff;
  background: $vimeo;
}

.social-box.hub i {
  color: #fff;
  background: white;
}

.icon-align-middle {
    display: inline-table; vertical-align:middle;
}

.abs-right {
    position: absolute;
    right:0.5rem;
    z-index: 100;
}

.top-075 {
    top: 0.75rem;
}

.width-1 {width: 1em;}
.width-2 {width: 2em;}
.height-1 {height: 1em;}
.height-2 {height: 2em;}
.height-40 {height: 40px;}

.width-xl {width: 1.25rem;}
.width-2xl {width: 1.5rem;}
.height-xl {height: 1.25rem;}
.height-2xl {height: 1.5rem;}

.font-size-inherit {font-size:inherit;}

.item-avatar {height:2.5em; width:2.5em; display:inline-table; vertical-align:middle;}

.hub-avatar {height:2.75em; width:2.75em; display:inline-table; vertical-align:middle;}
.hub-avatar-placeholder {height:2.75em; width:2.75em; border-radius:50em;
    background: url("/public/images/user-placeholder-squared.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    }

.item-title a, .item-meta a, .item-tags a {color: inherit;}

.inner-avatar-placeholder {
    background: url("/public/images/user-placeholder-squared.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width:2.5em; height:2.5em;
    border-radius: $border-radius;
}

.mm-messages { position:fixed; top:15px; left:15px; z-index:10000; text-align:center; }

.fade.in { opacity: 1; }

.w-10 {width: 10%;}
.w-80 {width: 80%;}
.w-90 {width: 90%;}

.mt-4 {
    margin-top: 4rem!important;
}

.mt-5 {
    margin-top: 5rem!important;
}

.mb-4 {
    margin-bottom: 4rem!important;
}

.mb-5 {
    margin-bottom: 5rem!important;
}

.mc-20 {
  margin-top: 40vh;
  margin-bottom: 40vh;
}

button.list-group-item:focus {
    outline:none;
}

.list-group-item.active {
    margin-bottom: -1px;
}

button.list-group-item {
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    cursor: pointer;
}

.sidebar .nav .divider {
    height: 6px;
}

.nav-title.btn-clear {
    padding: 0.5rem 1rem !important;
}

.c-pointer {
    cursor:pointer;
}

.trix-content {background-color: white; min-height: 5rem; width:100%}

trix-toolbar .trix-button-group--file-tools {
  display: none;
}

.tab-content {
    margin-bottom: 1rem;
}

.hidden {display:none!important;}

.avatar-upload {width:100%; height: 640px; margin-left: auto; margin-right: auto;}

.mm-chevron-up-down:before {content: "\f078";}
.is-set-open .mm-chevron-up-down:before {content: "\f077";}
.connected-stories .mm-chevron-up-down:before {content: "\f077";}
.is-connected-set-open .mm-chevron-up-down:before {content: "\f078";}

.mm-media-object.pdf iframe {width:100%; height:800px; max-height:800px;}

.card-link-unavailable,
.card-link-unavailable:hover,
.card-link-unavailable:active,
.card-link-unavailable:focus {
  color: #A0A0A0;
}

.btn-alternate.active {
    border-bottom: 2px solid;
}

.video-js {
    width: 100%;
}

.mm-blog {
  max-width:640px;
}

.bg {
	height: 320px;
	background-repeat: no-repeat; 
	background-position: center
}

.bg.bg-cover {
	background-image: url('/public/stylesheets/website/img/mm-cover-photo-tile-flat-t15.png');
	background-size: cover;
}

.bg.bg-contain {
	background-size: contain;
}

.overlay {
  position: absolute;
  background: rgba(255,255,255,.75);
  z-index: 20;
}

@media(max-width: 768px) {
  .gallery-block.overlay {
    position: inherit;
    background: unset;
    z-index: unset;
  }
}

.overlay-top {
  top: 10rem;
}

.slideshow-block.overlay-bottom {
  bottom: 6rem;
}

.gallery-block.overlay-bottom {
  bottom: 1rem;
}

.card-block.overlay {
  left: 15px;
  right:15px;
}

.slideshow-block.overlay {
	left: 0px;
	right: 0px;
}

// Slideshow styles

//@media(min-width: 992px) {
//  .mm-embed {height:640px; max-height:640px}
//}

//@media (min-width: 768px) {
//  .mm-embed {height:480px; max-height:480px}
//}

//@media(max-width: 576px) {
//  .mm-embed {height:320px; max-height:320px}
//}

// Carousel fade effect from http://codepen.io/Rowno/pen/Afykb
.carousel-fade {
    .carousel-inner {
        .carousel-item {
            transition-property: opacity;
        }

        .carousel-item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}

// Bootstrap 3 Carousel With Progress Bar from http://codepen.io/elgsantos/pen/doknj/
.transition-timer-carousel-progress-bar {
  height: 3px;
  background-color: $brand-danger;
  width: 0%;
  margin: 0px 0px 0px 0px;
  border: none;
  z-index: 11;
  position: absolute;
}

//.full-screen {
//  position:relative;
//  width: 100%; 
//  background-size: cover; 
//  background-position: center top; 
//  box-shadow: inset 0 0 2.125rem rgba(0,0,0,0.2); 
//  text-shadow: 0 0 1px rgba(0,0,0,0.1);
//}

//.contain-screen {
//  position:relative;
//  width: 100%; 
//  background-size: contain; 
//  background-position: center top; 
//  box-shadow: inset 0 0 2.125rem rgba(0,0,0,0.2); 
//  text-shadow: 0 0 1px rgba(0,0,0,0.1);
//  background-repeat: no-repeat;
//}

//.contain-screen {
//    min-height:100vh; 
//s}

.contain-screen .wrapper, .full-screen .wrapper {
  color: white;
  margin-top: 15vh;
}

.wrapper {position:absolute; bottom:5vh;}

.slideshow .carousel-control {height:40px; width: inherit !important; margin-top:50px;}

.slideshow .audiojs { width: 100%!important;}
.slideshow .audiojs .scrubber { width: 75% !important;}
.slideshow .audiojs .play-pause {width: 36px!important;}

.slideshow.full-screen .vjs-poster {
  background-size: cover !important;
}

.slideshow.contain-screen .vjs-poster {
  background-size: contain !important;
}

.slideshow .video-js {
  height: 100vh!important;
  }

.slideshow video {
  height: 100%;
}

.video-js {width:100%!important; height: 450px!important}

@media (max-width:767px) {
    .video-js {width:100%!important; height: 350px!important}
}

@media (max-width:480px) {
    .video-js {width:100%!important; height: 250px!important}
}

.carousel-control .fa-icon {
    //margin-top: -50%;
    //position: absolute;
    //top: 50%;
}

.carousel-control.left .fa-icon {
    left: 2px;
}
.carousel-control.right .fa-icon {
    right: 2px;
}

.contain-screen a, .contain-screen a:hover, .contain-screen a:focus {
    color: inherit;
    text-decoration: underline
}

.full-screen a, .full-screen a:hover, .full-screen a:focus {
    color: inherit;
    text-decoration: underline
}

//.full-screen {
//    min-height:100vh; 
//  }

.full-screen .wrapper {
  color: white;
  margin-top: 15vh;
}

.contain-screen .wrapper {
  color: white;
  margin-top: 15vh;
}

.contain-screen .cta-wrapper {
  margin-top:13vh; margin-bottom:13vh;
}

// Bootstrap 3 Carousel With Progress Bar from http://codepen.io/elgsantos/pen/doknj/
.transition-timer-carousel-progress-bar {
  height: 3px;
  background-color: $brand-danger;
  width: 0%;
  margin: 0px 0px 0px 0px;
  border: none;
  z-index: 11;
  position: absolute;
}

.item.active .carousel-navbar {display:inline}

// Carousel fade effect from http://codepen.io/Rowno/pen/Afykb
.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }

        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}

.carousel-indicators {
	top: 3rem;
	bottom: unset;
}

.slideshow.full-screen .slideshow-thumb {
  position:relative;
  width: 100%; 
  background-size: cover; 
  background-position: center top;
  background-color: #263238;
  min-height:100vh;
}

.slideshow.contain-screen .slideshow-thumb {
  position:relative;
  width: 100%; 
  background-size: contain; 
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #263238;
  min-height:100vh;
}

.slideshow.full-screen .container-pdf {
  height:100%; 
  max-height:100vh;
  width:100%;
}

.slideshow.contain-screen .container-pdf {
  height:100%; 
  max-height:100vh;
  width:100%;
}

.audio-wrapper {
  position: absolute; 
  bottom:0px; 
  width: 100%;
  height: 3rem;
}

.video-wrapper {
  position: absolute;
  top:0px;
  width: 100%;
  height: 100%
}

.slideshow .advanced-actions {
    position: absolute;
    width: 200px;
    top: 0;
    background: rgba(255,255,255,0.4);
}

a.btn, a.btn:hover {text-decoration:none}

.connected-stories {
  background: rgba(255,255,255,.75); 
}

.explore-hub {
  background: rgba(255,255,255,.75); 
}

.page-item button {
  border: 1px solid #ddd;
}

button {
  cursor: pointer; }
input.btn {
  cursor: pointer;
}

.page-item input {
  text-align: center;
  border: 1px solid #ddd;
}

.card-640 {
  max-width: 640px;
}

a.img {
  cursor:pointer
}

.consent {
  background: rgba(0,0,0,0.8); 
  width: 100vw; 
  height: 100vh; 
  z-index: 9999; 
  position: fixed;
}

 .youtube-iframe {
  display: block;       /* iframes are inline by default */
  background: #000;
  border: none;         /* Reset default border */
  height: 100vh;        /* Viewport-relative units */
  width: 100vw;
}

.b-1 {
  border: 1px solid #b1b1b1
}

.main .container-fluid {
  padding: 0 16px;
}

body.isEmbed .main .container-fluid {
  padding: 0px;
}

//fix to show the md-cards layout
.sidebar-hidden .app-body {
    overflow-x: visible;
}

//.sidebar-hidden.isEmbed .app-body {
//    overflow-x: inherit;
//    overflow-y: inherit;
//}

// custom md-cards for the tlt theme
.md-button.md-tlt-theme.md-fab.flat {
    box-shadow: none;
}

//.md-button.md-tlt-theme.md-fab.flat:hover {
//    background-color: #efefef !important;
//}

.md-icon-button.md-button.md-tlt-theme.mini {
    padding-left:3px;
    padding-right:3px;
    width: 24px;
    height:20px;
}

.mini > md-icon.md-tlt-theme {
    width:20px;
    height: 20px;
    min-width:20px;
    min-height: 20px;
}

.md-tlt-theme.item > .md-fab-toolbar-wrapper {
    height:52px;
}

.md-tlt-theme.item .md-fab-toolbar-content {
    height:52px;
}

md-toolbar.md-tlt-theme.item {
    height: 52px;
    min-height: 52px;
}

.md-tlt-theme.item > .md-toolbar-tools {
    height: 52px;
}

md-card.md-tlt-theme > md-card-title {
    padding: 16px;
}

.item-title {
  word-break: break-word;
}

//.slideshow .footer-fixed .container-fluid {
//  margin-bottom: 0px !important;
//}

.footer-fixed .container-fluid {
  margin-bottom: 55px;
}

.footer-fixed.isEmbed:not(.showFooter) .container-fluid {
  margin-bottom: 0px;
}

.h3 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .010em;
  margin: 0;
  line-height: 1.2em;
}

.setMedia .green {
  background: #b9f6ca; }

.setMedia .purple {
  background: #E1BEE7; }

.setMedia .red {
  background: #FFCDD2; }

.setMedia .action {
  min-height: 250px;
  padding: 2em;}

.setMedia.active {
  background-color: rgba(128,128,128,0.3);}

.social-box > div.bt {
  border-top: 1px solid #cfd8dc;
}

.md-button.md-tlt-theme.active {
  border-bottom: 2px solid;
}

.md-button.md-tlt-theme.tlt-no-transform {
  text-transform: none;
}

.md-button.md-tlt-theme.tlt-success {
  background-color:#aaf255
}

.icon-2x {
  font-size:2em;
}

.icon-3w {
  width:48px;
  height:48px
}

.icon-2w {
  width:36px;
  height:36px
}

.icon-bold {
  font-weight:bold;
}

.introjs-skipbutton {
  display: none !important;
}

@media (max-width: 600px) {
  .navbar-toggler {
    position: relative !important;
  }
}

@media (max-width: 991px) {
  .sidebar-mobile-show .main {
    margin-right: unset !important;
    margin-left: unset !important;
  }
}

.form-group .badge{
  word-break: break-all;
  white-space: inherit;
}

.form-group {
  margin-bottom: 3rem;
}

.splash.culturemoves {
  background-image: url('/public/hubs/CMoves/banner.jpg');
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.text-upper {
  text-transform: uppercase;
}