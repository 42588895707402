// Bootstrap overrides

// Colors
//
// Grayscale and brand colors for use across Bootstrap.

$white:  #fff;
$black:  #000;

$tellit-color: rgb(38,50,56);

$gray-dark:                 #263238;
$gray:                      #607d8b;
$gray-light:                #b0bec5;
$gray-lighter:              #cfd8dc;
$gray-lightest:             #eceff1;

$brand-primary:             $tellit-color;

$brand-success:             #4dbd74;
$brand-info:                #63c2de;
$brand-warning:             #f8cb00;
$brand-danger:              #f86c6b;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions:        true;
$enable-rounded:            false;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.

$spacer:   1rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
  q: (
    x:   ($spacer-x * 0.25),
    y:   ($spacer-y * 0.25)
  ),
  h: (
    x:   ($spacer-x * 0.5),
    y:   ($spacer-y * 0.5)
  ),
  0: (
    x:   0,
    y:   0
  ),
  1: (
    x:   $spacer-x,
    y:   $spacer-y
  ),
  2: (
    x:   ($spacer-x * 1.5),
    y:   ($spacer-y * 1.5)
  ),
  3: (
    x:   ($spacer-x * 3),
    y:   ($spacer-y * 3)
  )
);

// Body
//
// Settings for the `<body>` element.

$body-bg:              $white;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base:             0.875rem;

// Breadcrumbs

$breadcrumb-bg:                 #fff;

// Cards

$card-border-color:        $gray-lighter;
$card-cap-bg:              $gray-lightest;

// Dropdowns

$dropdown-padding-y:             0;
$dropdown-border-color:          $gray-lighter;
$dropdown-divider-bg:            $gray-lightest;

// Buttons

$btn-secondary-border:          $gray-light;

// Progress bars

$progress-bg:                   $gray-lightest;

// Tables

$table-bg-accent:               $gray-lightest;
$table-bg-hover:                $gray-lightest;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1600px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1540px
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width-base:     1rem;

//Navs

$nav-link-padding:           .5em .5em;
